<template>
  <v-container fluid class="width-80">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Integrations
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 70vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="12" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Configuration
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row v-if="false">
            <v-col md="2">
              <v-img
                :lazy-src="$defaultImage"
                :src="$assetURL('media/logos/google-calendar.png')"
                max-height="100"
                contain
                position="center center"
              ></v-img>
            </v-col>
            <v-col md="10" class="setting-configuration">
              <div>
                <h4 class="font-weight-bold color-custom-blue">
                  Google Calendar
                </h4>
                <p>
                  Integrate with Google Calendar - Automatic sync all visits and
                  reminders.
                </p>
              </div>
              <div>
                <v-btn
                  v-if="googleConnected"
                  v-on:click="googleDisconnect"
                  :loading="googleLoading"
                  :disabled="googleLoading || pageLoading"
                  small
                  class="text-white"
                  color="cyan"
                >
                  <span class="font-size-16 font-weight-600">Disconnect</span>
                </v-btn>
                <v-btn
                  v-else
                  v-on:click="googleConnect"
                  small
                  class="text-white"
                  color="cyan"
                  :loading="googleLoading"
                  :disabled="googleLoading || pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Connect</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="border-top">
            <v-col md="2">
              <v-img
                :lazy-src="$defaultImage"
                :src="$assetURL('media/logos/outlook-calendar.png')"
                max-height="100"
                contain
                position="center center"
              ></v-img>
            </v-col>
            <v-col md="10" class="setting-configuration">
              <div>
                <h4 class="font-weight-bold color-custom-blue">
                  Outlook Calendar
                </h4>
                <p>
                  Integrate with Outlook Calendar - Automatic sync all visits
                  and reminders.
                </p>
              </div>
              <div>
                <v-btn
                  v-if="outlookConnected"
                  v-on:click="outlookDisconnect"
                  :loading="outlookLoading"
                  :disabled="outlookLoading || pageLoading"
                  small
                  class="text-white"
                  color="cyan"
                >
                  <span class="font-size-16 font-weight-600">Disconnect</span>
                </v-btn>
                <v-btn
                  v-else
                  v-on:click="outlookConnect"
                  small
                  class="text-white"
                  color="cyan"
                  :loading="outlookLoading"
                  :disabled="outlookLoading || pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Connect</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET } from "@/core/services/store/request.module";

export default {
  data() {
    return {
      googleConnected: false,
      popupStatus: null,
      timeInterval: null,
      googleLoading: false,
      pageLoading: false,
      outlookConnected: false,
      outlookLoading: false,
      outlookPopup: null,
      outlookTimeInterval: null,
    };
  },
  methods: {
    outlookDisconnect() {
      const _this = this;
      _this.outlookLoading = true;
      _this.$store
        .dispatch(GET, { url: "outlook/calendar/disconnect" })
        .then(() => {
          _this.outlookConnected = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.outlookLoading = false;
        });
    },
    outlookConnect() {
      const _this = this;
      _this.outlookLoading = true;
      _this.$store
        .dispatch(GET, { url: "outlook/calendar/url" })
        .then(({ data }) => {
          _this.goConnect(data["login-url"]);
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    googleConnect() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(GET, { url: "google/calendar/url" })
        .then(({ data }) => {
          _this.goConnect(data.url);
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    googleDisconnect() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(GET, { url: "google/calendar/disconnect" })
        .then(() => {
          _this.googleConnected = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
        });
    },
    goConnect(url) {
      let left = screen.width / 2 - 536 / 2;
      let top = screen.height / 2 - 634 / 2;
      this.popupStatus = window.open(
        url,
        "",
        "resizable=yes,top=" + top + ",left=" + left + ",width=536,height=634"
      );
      this.watchPopup();
    },
    checkGoogleStatus() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "google/calendar/status" })
        .then(({ data }) => {
          if (data.status) {
            _this.googleConnected = true;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
        });
    },
    checkOutlookStatus() {
      const _this = this;
      _this.outlookLoading = true;
      _this.$store
        .dispatch(GET, { url: "outlook/calendar/status" })
        .then(({ data }) => {
          console.log({ data });
          if (data.status) {
            _this.outlookConnected = true;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.outlookLoading = false;
        });
    },
    watchPopup() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(function () {
        if (_this.popupStatus && _this.popupStatus.closed) {
          _this.popupStatus = null;
          clearInterval(_this.timeInterval);
          setTimeout(function () {
            _this.checkGoogleStatus();
            _this.checkOutlookStatus();
          }, 2000);
        }
      }, 1000);
    },
  },
  mounted() {
    this.checkGoogleStatus();
    this.checkOutlookStatus();
  },
};
</script>
